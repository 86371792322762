import { useGoogleAnalyticsListItemHandler } from '@graphcommerce/googleanalytics'
import { ProductListItem, ProductListItemRenderer } from '@graphcommerce/magento-product'

import { ProductListItemDownloadable } from '@graphcommerce/magento-product-downloadable'
import { ProductListItemGrouped } from '@graphcommerce/magento-product-grouped'
import { ProductListItemVirtual } from '@graphcommerce/magento-product-virtual'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductWishlistChip } from '@graphcommerce/magento-wishlist'
import { useTheme } from '@mui/material'
import { ProductListItemBundle } from './magento-product-bundle/ProductListItemBundle'
import { ProductListItemConfigurable } from './magento-product-configurable/ProductListItemConfigurable'
import { ProductListItemSimple } from './magento-product-simple'

export const productListRenderer: ProductListItemRenderer = {
  Skeleton: (props) => <ProductListItem {...props} aspectRatio={[1, 1]} />,
  SimpleProduct: (props) => {
    const handler = useGoogleAnalyticsListItemHandler(props)
    const { rating_summary, uid } = props
    const theme = useTheme()
    return (
      <ProductListItemSimple
        {...props}
        key={uid}
        aspectRatio={[1, 1]}
        bottomLeft={!!rating_summary && <ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        onClick={handler}
        sizes={{
          0: '175px',
          [theme.breakpoints.values.sm]: '200px',
          [theme.breakpoints.values.md]: '270px',
        }}
      />
    )
  },
  ConfigurableProduct: (props) => {
    const handler = useGoogleAnalyticsListItemHandler(props)
    const theme = useTheme()

    return (
      <ProductListItemConfigurable
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        swatchLocations={{
          topLeft: [],
          topRight: [],
          bottomLeft: [],
          bottomRight: [],
          extra: ['colour_detail'],
        }}
        onClick={handler}
        sizes={{
          0: '175px',
          [theme.breakpoints.values.sm]: '200px',
          [theme.breakpoints.values.md]: '270px',
        }}
      />
    )
  },
  BundleProduct: (props) => {
    const handler = useGoogleAnalyticsListItemHandler(props)

    return (
      <ProductListItemBundle
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        topRight={<ProductWishlistChip {...props} />}
        onClick={handler}
      />
    )
  },
  VirtualProduct: (props) => {
    const handler = useGoogleAnalyticsListItemHandler(props)

    return (
      <ProductListItemVirtual
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        onClick={handler}
      />
    )
  },
  DownloadableProduct: (props) => {
    const handler = useGoogleAnalyticsListItemHandler(props)

    return (
      <ProductListItemDownloadable
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        onClick={handler}
      />
    )
  },
  GroupedProduct: (props) => {
    const handler = useGoogleAnalyticsListItemHandler(props)

    return (
      <ProductListItemGrouped
        {...props}
        aspectRatio={[1, 1]}
        bottomLeft={<ProductReviewSummary {...props} />}
        onClick={handler}
      />
    )
  },
}
