import { Image } from '@graphcommerce/image'
import { Button, responsiveVal } from '@graphcommerce/next-ui'
import { Typography, Box, useTheme } from '@mui/material'
import { setTitle } from '../utils'
import { RowBannerSliderSlideFragment } from './RowBannerSliderSlide.gql'

export function RowBannerSliderSlide(props: RowBannerSliderSlideFragment & { index: number }) {
  const { imageMobile, imageDesktop, copy, callToActionText, callToActionUrl, index } = props
  const t = useTheme()

  return (
    <Box sx={{ position: 'relative' }}>
      {imageMobile?.url && (
        <Image
          src={imageMobile?.url}
          width={720}
          height={800}
          quality={84}
          sizes={{ [t.breakpoints.values.md]: '1px', 0: '100vw' }}
          loading={index === 0 ? 'eager' : 'lazy'}
          alt={imageMobile?.alt || ''}
          sx={(theme) => ({
            display: 'block',
            width: '100%',
            height: '100% !important',
            objectFit: 'cover',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          })}
        />
      )}
      {imageDesktop?.url && (
        <Image
          src={imageDesktop?.url}
          width={1920}
          height={750}
          quality={84}
          sizes={{ [t.breakpoints.values.md]: '100vw', 0: '1px' }}
          alt={imageDesktop?.alt || ''}
          loading={index === 0 ? 'eager' : 'lazy'}
          sx={(theme) => ({
            display: 'none',
            width: '100%',
            height: '100% !important',
            objectFit: 'cover',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            [theme.breakpoints.up('md')]: {
              display: 'block',
            },
          })}
        />
      )}
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: '100%',
          height: '100%',
          padding: responsiveVal(20, 80),
          paddingBottom: responsiveVal(65, 80),
          // zIndex: 1,
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacings.xxl,
          },
        })}
      >
        <Box>
          {copy && (
            <Typography
              variant='h1'
              component='div'
              sx={(theme) => ({
                marginBottom: theme.spacings.xs,
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.up('md')]: {
                  maxWidth: '60%',
                },
                [theme.breakpoints.up('xl')]: {
                  maxWidth: '33%',
                },
              })}
              dangerouslySetInnerHTML={{
                __html: setTitle(copy),
              }}
            />
          )}
        </Box>
        <Box>
          {callToActionUrl && callToActionText && (
            <Button href={callToActionUrl} variant='contained' color='primary' size='medium'>
              {callToActionText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
