import { AddProductsToCartForm, ProductListItemsFragment } from '@graphcommerce/magento-product'
import { RenderType } from '@graphcommerce/next-ui'
import { SxProps, Theme, Typography, Breakpoint, useTheme } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems'
import { setTitle } from '../../utils'
import { RowProductFragment } from '../RowProduct.gql'
import { ProductSlider } from '../components/ProductSlider'

type SwipeableProps = Omit<RowProductFragment, 'pageLinks'> &
  ProductListItemsFragment & { containerMaxWidth?: Breakpoint; sx?: SxProps<Theme> }

export function Swipeable(props: SwipeableProps) {
  const { title, items, containerMaxWidth = 'xl', sx = [] } = props
  const t = useTheme()

  if (!items || items.length === 0) return null

  return (
    <AddProductsToCartForm>
      <ProductSlider
        containerMaxWidth={containerMaxWidth}
        sidebar={
          <Typography
            component='h2'
            variant='h1'
            align='center'
            sx={(theme) => ({
              marginBottom: theme.spacings.md,
              textTransform: 'uppercase',
            })}
            dangerouslySetInnerHTML={{
              __html: setTitle(title),
            }}
          />
        }
        sx={[(theme) => ({ mb: theme.spacings.xxl }), ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {items?.map((item) =>
          item ? (
            <RenderType
              key={item.uid ?? ''}
              renderer={productListRenderer}
              sizes={{
                0: '28vw',
                [t.breakpoints.values.sm]: '24vw',
                [t.breakpoints.values.md]: '16vw',
                [t.breakpoints.values.lg]: '14vw',
                [t.breakpoints.values.xl]: '300px',
              }}
              {...item}
            />
          ) : null,
        )}
      </ProductSlider>
    </AddProductsToCartForm>
  )
}
