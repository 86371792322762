/* eslint-disable @next/next/no-img-element */
import { Asset } from '@graphcommerce/graphcms-ui'
import { NextLink } from '@graphcommerce/next-ui'
import { Box, Container, Link, useTheme } from '@mui/material'
import { RowInstagramFeedFragment } from './RowInstagramFeed.gql'
import btnFb from './img/btn-fb.svg'
import btnInsta from './img/btn-insta.svg'
import btnPinterest from './img/btn-pinterest.svg'
import btnYT from './img/btn-yt.svg'
import imgCrafted from './img/crafted2smile.svg'

/* @todo: we're using pain <img/> tags in this component as we get images served from Instagram's cdn.
 * Instagram uses several CDNs such as scontent-amt2-1.cdninstagram.com we cant possible whitelist all CDNs and for now we also cant whitelist
 * "*.cdninstagram.com" in our .env file (this will be possible in the future). When this is possible we should use the Next/Image component.
 */

type Props = RowInstagramFeedFragment & {
  posts?: {
    media_type?: string
    thumbnail_url?: string
    media_url?: string
    caption?: string
    permalink?: string
    id?: string
  }[]
}

export function RowInstagramFeed(props: Props) {
  const { posts, manualMode, pageLinks } = props
  const Theme = useTheme()

  if (!manualMode) {
    if (!posts) {
      console.warn(
        'Found <RowInstagramFeed/> component but no posts! Please make sure the token in your env is valid, or generate a new one.',
      )
      return null
    }
  }

  return (
    <Container maxWidth='lg' sx={(theme) => ({ mb: theme.spacings.xxl })}>
      <Box
        sx={(theme) => ({
          py: theme.spacings.md,
          display: 'grid',
          gridTemplateAreas: '"images links"',
          placeContent: 'center',
          placeItems: 'center',
          gap: theme.spacings.xs,
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: `55% 1fr`,
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
              "links"
              "images"
            `,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            display: 'grid',
            gridArea: 'images',
            gridTemplateColumns: `1fr 1fr 1fr`,
            gridTemplateRows: '1fr 1fr 1fr',
            gap: theme.spacings.sm,
            '& a': {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              aspectRatio: '1/1',
              overflow: 'hidden',
              '& img': {
                display: 'block',
                width: '100%',
                height: 'auto',
              },
            },
          })}
        >
          {manualMode
            ? pageLinks.slice(0, 9).map((pageLink) => (
                <Box
                  href={pageLink.url}
                  key={pageLink.id}
                  component={NextLink}
                  rel='noreferrer'
                  target='_blank'
                >
                  {pageLink?.asset && (
                    <Asset
                      asset={pageLink.asset}
                      sx={{
                        display: 'block',
                      }}
                      sizes={{
                        0: '33vw',
                        [Theme.breakpoints.values.sm]: '15vw',
                        [Theme.breakpoints.values.md]: '16vw',
                        [Theme.breakpoints.values.xl]: '246px',
                      }}
                      // layout='fixed'
                      loading='eager'
                    />
                  )}
                </Box>
              ))
            : posts &&
              posts.map((post) => {
                const src = post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url
                const caption = post.caption ? post.caption : 'Instagram image'

                return (
                  <a href={post.permalink} target='_blank' rel='noreferrer' key={post.id}>
                    <img src={src} alt={caption} loading='lazy' />
                  </a>
                )
              })}
        </Box>
        <Box
          sx={(theme) => ({
            gridArea: 'links',
            [theme.breakpoints.down('sm')]: {
              marginBottom: theme.spacings.md,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              padding: theme.spacings.sm,
              [theme.breakpoints.down('sm')]: { '& img': { maxWidth: 220 } },
            })}
          >
            <img
              src={imgCrafted.src}
              alt='Crafted to Smile'
              loading='lazy'
              style={{
                width: '100%',
                height: 'auto',
                margin: '0 auto',
                display: 'block',
              }}
              width={413}
              height={252}
            />
          </Box>
          <div>
            <Box
              sx={(theme) => ({
                display: 'flex',
                marginBottom: theme.spacings.xxs,
                justifyContent: 'center',
                '& img': {
                  display: 'block',
                  margin: '0 7px',
                  opacity: '0.9',
                  transition: 'opacity 200ms',
                },
                [theme.breakpoints.down('sm')]: {
                  '& img': {
                    maxWidth: '38px',
                    margin: '0 5px',
                  },
                },
                '& img:hover': {
                  opacity: '1',
                },
              })}
            >
              <a href='https://www.instagram.com/vaessencreative/' target='_blank' rel='noreferrer'>
                <img
                  src={btnInsta.src}
                  alt='Vaessen Creative on Instagram'
                  width={43}
                  height={43}
                />
              </a>
              <a href='https://www.facebook.com/vaessencreative' target='_blank' rel='noreferrer'>
                <img src={btnFb.src} alt='Vaessen Creative on Facebook' width={43} height={43} />
              </a>
              <a
                href='https://nl.pinterest.com/vaessencreative/_created/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={btnPinterest.src}
                  alt='Vaessen Creative on Pinterest'
                  width={43}
                  height={43}
                />
              </a>

              <a
                href='https://www.youtube.com/user/vaessencreative?sub_confirmation=1'
                target='_blank'
                rel='noreferrer'
              >
                <img src={btnYT.src} alt='Vaessen Creative on Youtube' width={43} height={43} />
              </a>
            </Box>
            <Box style={{ textAlign: 'center' }}>
              <Link
                href='https://www.instagram.com/vaessencreative'
                underline='hover'
                sx={{
                  typography: 'h4',
                  color: 'secondary.main',
                  display: 'inline-block',
                  maxWidth: 250,
                }}
                target='_blank'
              >
                @vaessencreative
              </Link>
            </Box>
          </div>
        </Box>
      </Box>
    </Container>
  )
}
