import { ContentLinks } from '@graphcommerce/next-ui'
import { Link } from '@mui/material'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

export function Inline(props: RowContentLinksFragment) {
  const { title, contentLinks } = props

  return (
    <ContentLinks
      title={title}
      sx={(theme) => ({
        maxWidth: 1024,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacings.md,

        '& .ContentLinks-title': {
          color: 'primary.main',
          fontWeight: 'bold',
        },
      })}
    >
      {contentLinks.map((contentLink) => (
        <Link
          key={contentLink.id}
          href={contentLink.url}
          variant='body1'
          color='inherit'
          underline='hover'
        >
          {contentLink.title}
        </Link>
      ))}
    </ContentLinks>
  )
}
