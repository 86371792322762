import { Box, Button, Container } from '@mui/material'
import { GetProductsBySkuQuery } from '../../../graphql/GetProductsBySku.gql'
import { Swipeable } from '../RowProduct/variant/Swipeable'
import { RowProductListFragment } from './RowProductList.gql'

export function RowProductList(props: RowProductListFragment & GetProductsBySkuQuery) {
  const { title, callToActionText, callToActionLink, productsBySku } = props
  const items = productsBySku?.items

  if (!items) return null

  return (
    <>
      <Swipeable
        identity=''
        title={title}
        items={items}
        containerMaxWidth='lg'
        sx={(theme) => ({
          marginBottom:
            callToActionText && callToActionLink ? theme.spacings.md : theme.spacings.lg,
        })}
      />
      {callToActionText && callToActionLink && (
        <Container sx={(theme) => ({ marginBottom: theme.spacings.xxl })}>
          <Box sx={{ display: 'flex', placeContent: 'center' }}>
            <Button
              href={`/${callToActionLink}`}
              variant='outlined'
              color='secondary'
              sx={{ boxShadow: 4 }}
            >
              {callToActionText}
            </Button>
          </Box>
        </Container>
      )}
    </>
  )
}
