import { Container } from '@mui/material'
import Script from 'next/script'
import { useCallback, useEffect } from 'react'
import { RowFormsAppFragment } from './RowFormsApp.gql'

export function RowFormsApp(props: RowFormsAppFragment) {
  const { formId } = props

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const init = useCallback(() => {
    // eslint-disable-next-line new-cap, no-new
    new globalThis.formsapp(formId, 'standard', { width: '100%', height: 'formHeight' })
  }, [formId])

  useEffect(() => {
    if (globalThis.formsapp && !document.querySelector('iframe[src*="forms.app"]')) {
      // eslint-disable-next-line no-new, new-cap
      new globalThis.formsapp(formId, 'standard', { width: '100%', height: 'formHeight' })
    }
  }, [formId])

  return (
    <Container>
      <div {...({ formsappid: formId } as any)} />
      <Script src='https://forms.app/static/embed.js' strategy='afterInteractive' onLoad={init} />
    </Container>
  )
}
