import { Image } from '@graphcommerce/image'
import { Box, css } from '@mui/material'

type labelProps = {
  label: {
    style?: string | null
    size?: string | null
    is_visible?: boolean | null
    image?: string | null
    name?: string | null
    position?: string | null
    product_id?: number | null
    txt?: string | null
  }
}

export function AMLabelRenderer(props: labelProps) {
  const { label } = props

  return (
    label && (
      <>
        {label.txt !== '' && (
          <Box>
            <Box
              sx={{
                borderRadius: 1,
                backgroundColor: 'text.primary',
                color: 'primary.contrastText',
                padding: '5px 10px',
                display: 'inline-block',
                mb: '5px',
              }}
              css={css`
                ${label.style}
              `}
            >
              {label.txt}
            </Box>
          </Box>
        )}
        {!!label.image && (
          <Box
            sx={{
              '& img': {
                maxWidth: 85,
              },
            }}
          >
            {label.image && (
              <Image src={`/${label.image}`} sizes='100px' width={150} height={150} />
            )}
          </Box>
        )}
      </>
    )
  )
}
