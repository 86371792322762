import { ProductListItemsBase, ProductItemsGridProps } from '@graphcommerce/magento-product'
import { productListRenderer } from './productListRenderer'

export type ProductListItemsProps = Omit<ProductItemsGridProps, 'renderers'>

export function ProductListItems(props: ProductListItemsProps) {
  const { size = 'normal' } = props

  return (
    <ProductListItemsBase
      renderers={productListRenderer}
      sx={[
        (theme) => ({
          maxWidth: theme.breakpoints.values.xl,
          margin: '0 auto',
          gridColumnGap: theme.spacings.sm,
          gridRowGap: theme.spacings.sm,
          px: { xs: 0, sm: '40px', md: '60px', lg: '120px' },
        }),
        size === 'normal' && {
          '&.sizeNormal': {
            gridTemplateColumns: {
              xs: `repeat(2, 1fr)`,
              sm: `repeat(3, 1fr)`,
              md: `repeat(4, 1fr)`,
              lg: `repeat(5, 1fr)`,
              xl: `repeat(5, 1fr)`,
            },
          },
        },
      ]}
      {...props}
    />
  )
}
