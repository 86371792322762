import {
  ProductListItem_ConfigurableProduct_Fragment,
  ProductListItem_SimpleProduct_Fragment,
} from '@graphcommerce/magento-product'
import { PluginProps } from '@graphcommerce/next-config'
import { Box } from '@mui/material'

export const component = 'ProductWishlistChip'
export const exported = '@graphcommerce/magento-wishlist'

function GaWishlistAdd(
  props: PluginProps<
    ProductListItem_SimpleProduct_Fragment | ProductListItem_ConfigurableProduct_Fragment
  >,
) {
  const { Prev } = props

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation() // stops triggering other events
        const isCurrentlyAdded = (e.target as Element)
          .closest('svg')
          ?.classList.contains('ProductWishlistChipBase-wishlistIconActive')

        /*
         * If the svg currently does not have an active class, the user is adding this item to his wishlist (as the actual adding is done lateer inside ProductWishlistChip)
         */
        if (!isCurrentlyAdded) {
          const { brand, name, sku, price_range } = props
          const viewItem = {
            currency: price_range?.minimum_price.final_price.currency ?? '',
            value: price_range?.minimum_price.final_price.value ?? '',
            items: {
              item_id: sku,
              item_name: name,
              item_brand: brand?.label ?? '',
            },
          }
          globalThis.gtag?.('event', 'add_to_wishlist', viewItem)
        }
      }}
    >
      <Prev {...props} />
    </Box>
  )
}

export const Plugin = GaWishlistAdd
