/* eslint-disable @next/next/no-img-element */
import { Image } from '@graphcommerce/image'
import { Box, Container, Link } from '@mui/material'
import btnFb from '../RowInstagramFeed/img/btn-fb.svg'
import btnInsta from '../RowInstagramFeed/img/btn-insta.svg'
import btnPinterest from '../RowInstagramFeed/img/btn-pinterest.svg'
import btnYT from '../RowInstagramFeed/img/btn-yt.svg'
import imgCrafted from '../RowInstagramFeed/img/crafted2smile.svg'
import { RowCraftedToSmileFragment } from './RowCraftedToSmile.gql'

export type RowCraftedToSmileProps = RowCraftedToSmileFragment

export function RowCraftedToSmile(props: RowCraftedToSmileProps) {
  const { socialImg, text } = props

  return (
    <Container>
      <Box
        sx={(theme) => ({
          py: theme.spacings.md,
          display: 'grid',
          mb: theme.spacings.xxl,
          gridTemplateAreas: '"image links"',
          placeContent: 'center',
          placeItems: 'center',
          gap: theme.spacings.xs,
          [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: `55% 1fr`,
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
          "links"
          "image"
        `,
          },
        })}
      >
        <Box
          sx={() => ({
            width: '80%',
            gridArea: 'image',
            '& img': {
              display: 'block',
            },
          })}
        >
          {socialImg && (
            <Image
              src={socialImg.url}
              height={(socialImg.height && socialImg.height) || 0}
              width={(socialImg.width && socialImg.width) || 0}
              layout='responsive'
            />
          )}
        </Box>
        <Box
          sx={(theme) => ({
            gridArea: 'links',
            [theme.breakpoints.down('sm')]: {
              marginBottom: theme.spacings.md,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              padding: theme.spacings.sm,
              [theme.breakpoints.down('sm')]: { '& img': { maxWidth: 220 } },
            })}
          >
            <img
              src={imgCrafted.src}
              alt='Crafted to Smile'
              loading='lazy'
              style={{
                width: '100%',
                height: 'auto',
                margin: '0 auto',
                display: 'block',
              }}
            />
          </Box>
          <div>
            <Box
              sx={(theme) => ({
                display: 'flex',
                marginBottom: theme.spacings.xxs,
                justifyContent: 'center',
                '& img': {
                  display: 'block',
                  margin: '0 7px',
                  opacity: '0.9',
                  transition: 'opacity 200ms',
                },
                [theme.breakpoints.down('sm')]: {
                  '& img': {
                    maxWidth: '38px',
                    margin: '0 5px',
                  },
                },
                '& img:hover': {
                  opacity: '1',
                },
              })}
            >
              <a href='https://www.instagram.com/vaessencreative/' target='_blank' rel='noreferrer'>
                <img
                  src={btnInsta.src}
                  alt='Vaessen Creative on Instagram'
                  width={43}
                  height={43}
                />
              </a>
              <a href='https://www.facebook.com/vaessencreative' target='_blank' rel='noreferrer'>
                <img src={btnFb.src} alt='Vaessen Creative on Facebook' width={43} height={43} />
              </a>
              <a
                href='https://nl.pinterest.com/vaessencreative/_created/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={btnPinterest.src}
                  alt='Vaessen Creative on Pinterest'
                  width={43}
                  height={43}
                />
              </a>

              <a
                href='https://www.youtube.com/user/vaessencreative?sub_confirmation=1'
                target='_blank'
                rel='noreferrer'
              >
                <img src={btnYT.src} alt='Vaessen Creative on Youtube' width={43} height={43} />
              </a>
            </Box>
            <Box style={{ textAlign: 'center' }}>
              <Link
                href='https://www.instagram.com/vaessencreative/'
                underline='hover'
                sx={{
                  typography: 'h4',
                  color: 'secondary.main',
                  display: 'inline-block',
                  lineHeight: 1.2,
                  maxWidth: 250,
                }}
                target='_blank'
              >
                {text ?? 'Share your creations with #craftedtosmile'}
              </Link>
            </Box>
          </div>
        </Box>
      </Box>
    </Container>
  )
}
