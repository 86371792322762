import { Box, Container } from '@mui/material'
import { RowVideoFragment } from './RowVideo.gql'

type RowVideoProps = RowVideoFragment

export function RowVideo(props: RowVideoProps) {
  const { videolink, playerWidth } = props

  return (
    <Container maxWidth='md' sx={(theme) => ({ marginBottom: theme.spacings.lg })}>
      <Box style={{ aspectRatio: '16/9', width: playerWidth ?? '100%' }}>
        <iframe
          title={videolink}
          src={videolink}
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </Container>
  )
}
