/* eslint-disable react/destructuring-assignment */
import { useQuery } from '@graphcommerce/graphql'
import {
  OverlayAreaKeys,
  ProductListItemProps,
  isFilterTypeEqual,
  useProductListParamsContext,
} from '@graphcommerce/magento-product'
import {
  GetConfigurableOptionsSelectionDocument,
  ProductListItemConfigurableFragment,
} from '@graphcommerce/magento-product-configurable'
import { SwatchList } from '@graphcommerce/magento-product-configurable/SwatchList'
import { responsiveVal } from '@graphcommerce/next-ui'
import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ProductListItem } from '../magento-product/ProductListItem'

export type ProductListItemConfigurableActionProps = ProductListItemConfigurableFragment & {
  variant?: NonNullable<ProductListItemConfigurableFragment['variants']>[0]
}

export type ProdustListItemConfigurableProps = ProductListItemConfigurableFragment &
  ProductListItemProps & {
    Actions?: React.VFC<ProductListItemConfigurableActionProps>
    swatchLocations?: Record<OverlayAreaKeys, string[]> & { extra: string[] }
  }

export function ProductListItemConfigurable(props: ProdustListItemConfigurableProps) {
  const {
    Actions,
    variants,
    configurable_options,
    children,
    swatchLocations = { bottomLeft: [], bottomRight: [], topLeft: [], topRight: [], extra: [] },
    bottomLeft,
    bottomRight,
    topLeft,
    topRight,
    url_key,
    ...configurableProduct
  } = props
  const { params } = useProductListParamsContext()

  const options: [string, string[]][] =
    configurable_options
      ?.filter(
        (option) =>
          option?.attribute_code &&
          params.filters[option.attribute_code] &&
          isFilterTypeEqual(params.filters[option.attribute_code]),
      )
      .map((option) => {
        const filter = params.filters[option?.attribute_code ?? '']
        return [option?.attribute_code ?? '', (filter?.in as string[]) ?? []]
      }) ?? []

  const selected = {}

  options.forEach(([attr, values]) => {
    if (!selected[attr]) selected[attr] = values
  })

  const matchingVariants = variants?.filter(
    (variant) =>
      variant?.attributes?.filter(
        (attribute) =>
          selected[attribute?.code ?? ''] !== undefined &&
          selected[attribute?.code ?? ''].includes(String(attribute?.uid)),
      ).length,
  )

  const productProps = matchingVariants?.[0]?.product
    ? { ...configurableProduct, ...matchingVariants?.[0]?.product }
    : configurableProduct

  const colorSwatch = configurable_options?.find(
    (swatch) =>
      swatch?.attribute_code === 'colour_general' || swatch?.attribute_code === 'colour_detail',
  )

  const isSmallScreen = useMediaQuery('(max-width: 1279px)')
  let numSwatchesToShow = 6
  if (isSmallScreen) numSwatchesToShow = 4

  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [selectedConfigurableImage, setSelectedConfigurableImage] = useState('')
  const ref = useRef<HTMLDivElement>()
  const { data } = useQuery(GetConfigurableOptionsSelectionDocument, {
    variables: { urlKey: props.url_key ?? '', selectedOptions },
    skip: !props.url_key || !selectedOptions.length,
  })

  useEffect(() => {
    if (data?.products?.items?.[0]?.__typename === 'ConfigurableProduct') {
      if (
        data?.products?.items?.[0].configurable_product_options_selection?.media_gallery?.[0]
          ?.__typename === 'ProductImage'
      ) {
        const image =
          data?.products?.items?.[0].configurable_product_options_selection?.media_gallery?.[0]?.url
        if (image) {
          setSelectedConfigurableImage(image)
        }
      }
    }
  }, [data])

  return (
    <ProductListItem
      {...productProps}
      url_key={url_key}
      bottomRight={<>{bottomRight}</>}
      selectedConfigurableImage={selectedConfigurableImage}
    >
      {Actions && <Actions {...configurableProduct} variant={matchingVariants?.[0]} />}
      {children}
      <Box
        sx={{
          background: '#fff',
          display: 'flex',
          gap: '5px',
          padding: `10px ${responsiveVal(10, 20)} 0`,
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            gap: '5px',
            flexWrap: 'wrap',
            '& picture': { position: 'relative', zIndex: -1 },
            '& .MuiBox-root': {
              height: `24px`,
              width: '24px',
              clipPath: 'url(#myCurve)',
            },
            [`& .MuiBox-root:nth-of-type(n+${numSwatchesToShow + 1})`]: {
              display: 'none',
            },
            '& .MuiBox-root:nth-of-type(1)': {
              transform: 'rotate(15deg)',
            },
            '& .MuiBox-root:nth-of-type(2)': {
              transform: 'rotate(30deg)',
            },
            '& .MuiBox-root:nth-of-type(3)': {
              transform: 'rotate(245deg)',
            },
            '& .MuiBox-root:nth-of-type(4)': {
              transform: 'rotate(160deg)',
            },
            '& .MuiBox-root:nth-of-type(5)': {
              transform: 'rotate(200deg)',
            },
            '& .MuiBox-root:nth-of-type(6)': {
              transform: 'rotate(190deg)',
            },
            '& img': {
              width: '24px !important',
              height: '24px !important',
            },
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            if (ref.current) {
              const clickedIndex = [...ref.current.children].indexOf(e.target as Element)
              if (clickedIndex > -1) {
                setSelectedOptions([colorSwatch?.values?.[clickedIndex]?.uid as string])
              }
            }
          }}
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          ref={ref}
        >
          <SwatchList
            attributes={swatchLocations.extra}
            configurable_options={configurable_options}
          />
        </Box>
        {colorSwatch?.values && colorSwatch.values.length > numSwatchesToShow && (
          <span style={{ lineHeight: '24px' }}>
            +{colorSwatch.values.length - numSwatchesToShow}
          </span>
        )}
      </Box>
    </ProductListItem>
  )
}
