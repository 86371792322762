import { Scroller, ScrollerProvider, ScrollerButton } from '@graphcommerce/framer-scroller'
import { Asset } from '@graphcommerce/graphcms-ui'
import { iconChevronLeft, iconChevronRight, IconSvg } from '@graphcommerce/next-ui'
import { Container, Link, Typography, darken, styled, Box } from '@mui/material'
import { m } from 'framer-motion'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

const MotionDiv = styled(m.div)({})

const getBorderRadius = (index: number) => {
  const borderRadiusStyles = [
    '53% 47% 65% 35% / 54% 48% 52% 46%',
    '57% 43% / 56% 71% 29% 44%',
    '59% 41% 67% 33% / 31% 57% 43% 69%',
    '60% 40% 64% 36% / 41% 72% 28% 59%',
    '28% 72% 37% 63% / 36% 33% 67% 64%',
    '70% 30% 54% 46% / 44% 52% 48% 56%',
    '47% 53% 43% 57% / 65% 55% 45% 35%',
    '66% 34% 66% 34% / 62% 62% 38% 38%',
    '53% 47% 45% 55% / 49% 68% 32% 51%',
    '50% 50% 64% 36% / 34% 58% 42% 66%',
    '28% 72% 45% 55% / 70% 37% 63% 30%',
    '34% 66% 23% 77% / 71% 40% 60% 29%',
  ]

  return borderRadiusStyles[index % borderRadiusStyles.length]
}

export function ItemSlider(props: RowContentLinksFragment) {
  const { contentLinks } = props

  return (
    <Box sx={(theme) => ({ mb: theme.spacings.xxl })}>
      <Container>
        <ScrollerProvider scrollSnapAlign='end'>
          <MotionDiv
            sx={{
              position: 'relative',
              minWidth: 1,
              '& .ArrowButtonWrap': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 54,
                width: 42,
                height: 42,
                zIndex: 2,
              },
            }}
          >
            <Scroller
              hideScrollbar
              sx={{
                gridTemplateRows: '100%',
                columnGap: { xs: '30px', sm: '40px', md: '50px', lg: '65px' },
                height: 'auto',
                gridAutoColumns: 'min-content',
              }}
            >
              {contentLinks.map((pageLink, index) => (
                <Link
                  key={pageLink.url}
                  href={pageLink.url}
                  underline='none'
                  color='inherit'
                  align='center'
                  sx={(theme) => ({
                    width: '100%',
                    height: '100%',
                    display: 'grid',
                    gridAutoFlow: 'row',
                    '&:hover > div': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                  })}
                >
                  <Box
                    className={`index-${index}`}
                    sx={(theme) => ({
                      backgroundColor: darken(theme.palette.secondary.light, 0.025),
                      borderRadius: getBorderRadius(index),
                      aspectRatio: '1 / 1',
                      width: '100%',
                      height: { xs: '100px', sm: '130px', md: '140px', lg: '160px' },
                      transition: theme.transitions.create(['background-color'], {
                        duration: theme.transitions.duration.short,
                      }),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    })}
                  >
                    {pageLink?.asset && (
                      <Asset
                        asset={pageLink.asset}
                        layout='fixed'
                        sizes='160px'
                        sx={{
                          width: '160px',
                          height: '160px',
                          maxWidth: '85%',
                          maxHeight: '85%',
                          objectFit: 'contain',
                        }}
                        unoptimized={pageLink.asset.mimeType === 'image/svg+xml'}
                      />
                    )}
                  </Box>
                  <Typography
                    component='span'
                    variant='h6'
                    align='center'
                    sx={(theme) => ({
                      marginTop: `${theme.spacings.sm}`,
                      lineHeight: 1.5,
                      [theme.breakpoints.down('sm')]: {
                        typography: 'subtitle2',
                      },
                    })}
                  >
                    {pageLink.title}
                  </Typography>
                </Link>
              ))}
            </Scroller>
            <MotionDiv
              layout
              className='ArrowButtonWrap'
              sx={(theme) => ({
                left: 0,
                [theme.breakpoints.up('md')]: {
                  left: -21,
                },
              })}
            >
              <ScrollerButton
                direction='left'
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  '&:hover': {
                    background: darken(theme.palette.background.paper, 0.2),
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                  },
                })}
              >
                <IconSvg src={iconChevronLeft} />
              </ScrollerButton>
            </MotionDiv>
            <MotionDiv
              layout
              className='ArrowButtonWrap'
              sx={(theme) => ({
                right: 0,
                [theme.breakpoints.up('md')]: {
                  right: -21,
                },
              })}
            >
              <ScrollerButton
                direction='right'
                sx={(theme) => ({
                  backgroundColor: theme.palette.background.paper,
                  '&:hover': {
                    background: darken(theme.palette.background.paper, 0.2),
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                  },
                })}
              >
                <IconSvg src={iconChevronRight} />
              </ScrollerButton>
            </MotionDiv>
          </MotionDiv>
        </ScrollerProvider>
      </Container>
    </Box>
  )
}
