import { Scroller, ScrollerProvider, ScrollerDots } from '@graphcommerce/framer-scroller'
import { Box, SxProps, Theme } from '@mui/material'
import { useState } from 'react'
import { useStoreData } from '../../../lib/hooks/useStoreData'
import { RowBannerSliderFragment } from './RowBannerSlider.gql'
import { RowBannerSliderSlide } from './RowBannerSliderSlide'
import { AutoScroll } from './utils'

type RowBannerSliderProps = RowBannerSliderFragment & { sx?: SxProps<Theme> }

export function RowBannerSlider(props: RowBannerSliderProps) {
  const { visibleInScopes, bannerSliderSlides, sx = [] } = props
  const [isHover, setIsHover] = useState(false)

  const { mode } = useStoreData()
  const visibleInStoreView = visibleInScopes ? visibleInScopes?.toLowerCase() : 'b2c-b2b'

  if (visibleInStoreView !== 'b2c-b2b' && visibleInStoreView !== mode) return null

  return (
    <Box
      sx={[
        (theme) => ({ position: 'relative', mb: theme.spacings.xl }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <ScrollerProvider>
        <Scroller
          hideScrollbar
          style={{
            height: '60vh',
            gridAutoColumns: '100%',
            maxHeight: 750,
          }}
        >
          {bannerSliderSlides.map((slide, index) => (
            <RowBannerSliderSlide key={slide.imageDesktop?.url} {...slide} index={index} />
          ))}
        </Scroller>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: theme.spacings.sm,
            left: 0,
            zIndex: 2,
            width: '100%',
          })}
        >
          {bannerSliderSlides.length > 1 && (
            <Box
              sx={(theme) => ({
                height: 43,
                alignContent: 'center',
                background: theme.palette.background.paper,
                boxShadow: theme.shadows[3],
                borderRadius: 999,
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                  transform: 'scale(0.75)',
                },
              })}
            >
              <ScrollerDots />
            </Box>
          )}
        </Box>
        <AutoScroll pause={isHover} />
      </ScrollerProvider>
    </Box>
  )
}
