import { useQuery } from '@graphcommerce/graphql'
import { Row } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import {
  Box,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Theme,
} from '@mui/material'
import React, { useState } from 'react'
import { useProductPageContext } from '../../../../contexts/ProductPageContext'
import { ProductPage2Document } from '../../../../graphql/ProductPage2.gql'
import { ProductPageVaessenFieldsFragment } from '../../../../graphql/ProductPageVaessenFields.gql'
import { RowProductFragment } from '../RowProduct.gql'
import { TabPanel } from '../components/TabPanel'

export function Specs(props: RowProductFragment & Partial<ProductPageVaessenFieldsFragment>) {
  // update data when a simple product is configured
  const { configuredVariantUrl } = useProductPageContext()
  const { data } = useQuery(ProductPage2Document, {
    variables: {
      urlKey: configuredVariantUrl,
    },
    skip: configuredVariantUrl === '',
  })
  const configuredSimpleProduct = data?.products?.items?.find(
    (i) => i?.__typename === 'SimpleProduct',
  )

  const productData = configuredSimpleProduct || props
  const { description, sku, custom_attributes } = productData
  const [value, setValue] = useState(description?.html ? 0 : 1)

  // we dont want these attributes in our product detail tab
  const excludedAttributes = [
    'youtube_link',
    'ean_13',
    'product_bulletpoint_1',
    'product_bulletpoint_2',
    'product_bulletpoint_3',
  ]
  // filter excludedAttributes from custom_attributes
  const attributes =
    custom_attributes?.filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      (attribute) => !excludedAttributes.includes(attribute?.attribute_metadata?.code ?? ''),
    ) ?? []

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const blockSx: SxProps<Theme> = {
    textTransform: 'none',
    typography: 'h5',
    fontVariationSettings: 'unset',
    color: 'text.disabled',
    '&.Mui-selected': {},
  }

  return (
    <Row sx={{ typography: 'subtitle1' }} maxWidth='md'>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='product-details and shipping'
            variant='scrollable'
            scrollButtons={false}
          >
            {description && description.html && (
              <Tab
                sx={{ ...blockSx }}
                value={0}
                label={<Trans id='Product information' />}
                id='simple-tab-0'
                aria-controls='simple-tabpanel-0'
              />
            )}
            <Tab
              sx={{ ...blockSx }}
              value={1}
              label={<Trans id='Product details' />}
              id='simple-tab-1'
              aria-controls='simple-tabpanel-1'
            />
            <Tab
              sx={{ ...blockSx }}
              value={2}
              label={<Trans id='Shipping and returns' />}
              id='simple-tab-2'
              aria-controls='simple-tabpanel-2'
            />
          </Tabs>
        </Box>
        {description && description.html && (
          <TabPanel value={value} index={0}>
            <Box
              dangerouslySetInnerHTML={{ __html: description.html }}
              sx={{
                typography: 'body1',
                '& p:first-of-type': { mt: 0 },
                '& p:last-of-type': { mb: 0 },
              }}
            />
          </TabPanel>
        )}
        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Trans id='sku' />
                  </TableCell>
                  <TableCell>{sku}</TableCell>
                </TableRow>
                {attributes.map((attribute) => (
                  <TableRow key={attribute?.attribute_metadata?.label}>
                    <TableCell>{attribute?.attribute_metadata?.label}</TableCell>
                    <TableCell>
                      {attribute?.entered_attribute_value?.value}
                      {attribute?.selected_attribute_options?.attribute_option
                        ?.map((option) => option?.label)
                        .join(', ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box
            component='p'
            sx={{
              typography: 'body1',
              my: 0,
            }}
          >
            <Trans id="On business days we aim to dispatch all orders placed before 3pm on the same day. Orders received on the weekend will be shipped on Monday. You can return your orders within 30 days of receipt. Please refer to our FAQ's on how to obtain a return form. Shipping costs will not be refunded by us when returning items." />
          </Box>
        </TabPanel>
      </Box>
    </Row>
  )
}
